@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    background: #1f2937;
}

a{
    text-decoration: none;
}