@tailwind base;
@tailwind components;
@tailwind utilities;

#hyperLink{
    display: inline-block;
    position: relative;
    color: #0087ca;
}

#hyperLink::after{
    content: "";
    position: absolute;
    width: 92%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

#hyperLink:hover::after{
    transform: scaleX(1);
    transform-origin: bottom left;
}


#pjects {
    transition: text-shadow 0.3s ease;
}
  
#pjects:hover {
    text-shadow: 2px 2px 5px #0087ca;
}
  
