.max-w-4xl {
    max-width: 36rem;
}

.border {
border-width: 1px;
}

.rounded-lg {
border-radius: 0.5rem;
}

.p-4 {
padding: 1rem;
}

.mb-4 {
margin-bottom: 1rem;
}

.bg-white {
background-color: #fff;
}

.shadow-2xl {
box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.hover\:shadow-4xl:hover {
box-shadow: 0 35px 60px -15px rgba(0, 0, 0, 0.3);
}

.hover\:scale-105:hover {
transform: scale(1.05);
transition-duration: 0.3s;
}

.text-lg {
font-size: 1.125rem;
line-height: 1.75rem;
font-weight: 700;
}

.font-medium {
font-weight: 500;
}

.text-gray-500 {
color: #6b7280;
}

.text-gray-600 {
color: #4b5563;
}

ul {
list-style-type: disc;
margin-left: 1.5rem;
}

li {
margin-bottom: 0.5rem;
}
